import axios from 'axios';
import Alpine from 'alpinejs';
import 'https://kit.fontawesome.com/0e8dd29ebf.js';
import * as Sentry from "@sentry/browser";
import './sml_scripts';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import mapboxglSupported from "@mapbox/mapbox-gl-supported";

window.mapboxglSupported = mapboxglSupported;


window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.mapboxgl = mapboxgl;
window.mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

window.Alpine = Alpine;
Alpine.start();

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});

// Meta webview errors fix
window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {
}

// Vite
const images = import.meta.glob([
    '../images/**',
    '../video/**',
], {import: 'images'});

//import './showings';